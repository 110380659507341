<script setup>
  import TagBadge from '@/views/blog/components/TagBadge.vue'
  import { computed } from 'vue'
  import { truncateText } from '@/util/func'

  const props = defineProps({
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    showTags: {
      type: Boolean,
      default: false
    },
    readTime: {
      type: String,
      required: true
    }
  })
  const showTagComputed = computed(() => props.showTags)
</script>

<template>
<div class="p-8">
  <a :href="`/blogs/${slug}`"><img :src="image" alt="image" /></a>
  <div class="tags" v-if="showTagComputed">
    <TagBadge
    v-for="(tag, index) in tags"
    :key="tag.slug"
    :slug="tag.slug"
    :name="tag.name"
    :index="index+1"
    :show-badge="true"
    />
    <p class="readTime">{{ readTime }} min read</p>
  </div>
  <a :href="`/blogs/${slug}`"><h2>{{ title }}</h2></a>
  <p v-if="!showTagComputed" class="readTime">{{ readTime }} min read</p>
  <p>{{ !showTagComputed ? truncateText(description,80) : truncateText(description,150) }}</p>
</div>
</template>

<style scoped lang="less">
img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
h2{
  padding:8px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.019em;
  text-align: left;
  word-break: break-all;
}
p{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.019em;
  text-align: left;
  word-break: break-all;
  color: #606266;
}
.tags {
  display: flex;
  gap: 0.5rem;
  padding: 16px 0 8px 0;
}
.p-8{
  padding: 8px 0;
  a{
    width: 100%;
    text-decoration: none;
    color: black;
  }
}
.readTime{
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.019em;
  text-align: left;
  padding: 4px 4px 8px 0;
  color:#6f6f6f;
}

</style>