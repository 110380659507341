<script setup>
</script>

<template>
  <div  class="rowCC mt20 w12">
  <div id="proMain">
    <div class="loading-overlay" id="loadingOverlay">
      <div class="spinner"></div>
    </div>
  </div>
  </div>
</template>

<style scoped lang="less">
#proMain {
  position: relative;
  width: 300px;
  height: 400px;
  padding: 16px;
}

/* Loading overlay styling */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex; /* Hidden by default */
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/* Spinner styling */
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ddd;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>